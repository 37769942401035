<template>
    <div class="iframe-container" style="margin-bottom: 80px;">
      <div v-for="tm in tms">
        <div v-if="tm.type==='img'" style="width: 100%;">
          <img :src="tm.rimg" :style="img_style(tm.style)"/>
        </div>
        <div v-if="tm.type==='txt'" :style="txt_style(tm.style)">
          {{tm.data}}
        </div>
      </div>
    </div>

    <Footer></Footer>
</template>

<script>
import Footer from "../../components/Tabbar/index.vue";
import {defineComponent, reactive, ref, getCurrentInstance} from 'vue'
export default {
    name: "boss",
    components: {
        Footer
    },
    setup() {
        const {proxy,ctx} = getCurrentInstance();
        const _this= ctx;
        const tparams = proxy.$root.$route.params;
        const tpms = JSON.parse(tparams.tpms);
        let tms = [];
        if(tpms!=undefined){
          tms = tpms.tms;
          if(tms!=undefined&&tms.length>0){
            for (let i =0;i<tms.length;i++){
              if(tms[i].type=="img"){
                tms[i].rimg = require("../../assets/img/"+tms[i].data);
              }
            }
          }
          // _this.$root.set_title(tpms.title);
        }

        const img_style = (t_style) => {
          return 'padding-left: 10px; padding-top: 10px; width: calc(100% - 20px);'+t_style;
        }

        const txt_style = (t_style) => {
          return 'padding: 10px; padding-bottom: 0px;'+t_style;
        }

        return{
          tms,
          img_style,
          txt_style
        }
    }
}
</script>

<style scoped>

.iframe-container{
    height: 100%;
    width: 100%; 
    padding: 0;
}
.frame-item {
  min-height: 100vh;
  width: 100%;
}
</style>